import {
  Transition,
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
} from "@headlessui/react";
import { useState, useEffect, Fragment, useContext } from "react";
import ReactGA from "react-ga4";
import { ProfileContext } from "../../../util/ProfileContext";

export default function Onboarder(): JSX.Element {
  const [open, setOpen] = useState(false);
  const { profile, updateProfile } = useContext(ProfileContext);

  useEffect(() => {
    // check if the user needs to be onboarded
    // this is only set by login if the user needs it
    if (
      profile &&
      profile.id &&
      (profile.onboarded === undefined || profile.onboarded === false)
    ) {
      setOpen(true);
    }
  }, [profile]);

  const handleWaitlistConfirmation = () => {
    if (profile) {
      // Mark the user as onboarded in the system (even though they aren't fully onboarded)
      // This prevents the onboarding modal from showing up again
      const updatedProfile = { ...profile, onboarded: true };

      // Track the event
      ReactGA.event({
        category: "account",
        action: "waitlisted",
        label: "platform_capacity",
      });

      // Update the profile context
      updateProfile(updatedProfile);
      setOpen(false);
    }
  };

  return (
    <Transition show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        onClose={() => {
          // swallow the close event
        }}
      >
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>
        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white p-4">
                  <div className="mt-3 sm:mt-0">
                    <DialogTitle
                      as="h2"
                      className="text-3xl mb-4 w-full p-2 text-center"
                    >
                      ⏳ Platform at Capacity
                      <br />
                      <span className="text-2xl">
                        You've been added to our waitlist
                      </span>
                    </DialogTitle>
                  </div>
                  <div className="px-4 py-3">
                    <p className="mb-4">
                      Thank you for your interest in our platform! Currently,
                      we're operating at full capacity. Your account has been
                      added to our waitlist, and we'll notify you as soon as
                      space becomes available.
                    </p>
                    <p className="mb-6">
                      We appreciate your patience and look forward to welcoming
                      you to the platform soon.
                    </p>
                    <div className="text-right mt-8">
                      <button
                        type="button"
                        className="inline-flex justify-center rounded-md border border-transparent bg-toolkitTurquoise px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-toolkitTurquoise/90 focus:outline-none focus:ring-2 focus:ring-toolkitTurquoise focus:ring-offset-2"
                        onClick={handleWaitlistConfirmation}
                      >
                        I understand
                      </button>
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
